import React from 'react'
import Sheet from '@mui/joy/Sheet'
import Snackbar from '@mui/joy/Snackbar'

import Header from './Components/Header'
import URLTable from './Components/URLTable'

import useURL, { PageOptions, ShortUrl } from './Hooks/useURL'
import useAuthentication from './Hooks/useAuthentication'


const App = () => {
    const { isAuthenticated } = useAuthentication()
    const { getPublicURLCollection, getPrivateURLCollection, setVisibility, deleteURL } = useURL()
    const [urls, setURLs] = React.useState<ShortUrl[]>([])
    const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState<PageOptions['from']>(undefined)
    const [notificationMsg, setNotificationMsg] = React.useState<{msg: string, color: string}>({msg: '', color: 'neutral'})

    const loadData = () => {
        console.log('loading data')
        const loadFunction = isAuthenticated() ? getPrivateURLCollection : getPublicURLCollection

        loadFunction({ limit: 5, from: lastEvaluatedKey })
            .then(nextUrls => {
                const totalUrls = [...urls, ...nextUrls.Items]
                setURLs(totalUrls)
                setLastEvaluatedKey(nextUrls.LastEvaluatedKey)
            })
            .catch(e => console.error(e))
    }

    const handleNewUrl = (url: ShortUrl) => {
        setURLs([url, ...urls])
        setNotificationMsg({msg: 'URL added.', color: 'success'})
    }

    const handleDelete = async (slug: string) => {
        try {
            await deleteURL(slug)
            setNotificationMsg({msg: 'URL deleted.', color: 'success'})
            setURLs(urls.filter(url => url.slug !== slug))
        } catch (e) {
            console.error(e)
            setNotificationMsg({msg: 'Failed to delete URL.', color: 'danger'})
        }
    }

    const handleToggleVisibility = async (slug: string, newState: ShortUrl['visible']) => {
        try {
            const updatedState = await setVisibility(slug, newState)
            const updatedURLs = urls.map(url => {
                if (url.slug === slug) {
                    return { ...url, visible: updatedState }
                }
                return url
            })
            setURLs(updatedURLs)
            setNotificationMsg({msg: 'URL visibility updated.', color: 'success'})
        } catch (e) {
            console.error(e)
            setNotificationMsg({msg: 'Failed to update URL visibility.', color: 'error'})
        }
    }

    React.useEffect(loadData,[])

    React.useEffect(() => {
        setURLs([])
        setLastEvaluatedKey(undefined)
        console.log('unset data')
        loadData()
    }, [ isAuthenticated() ])

    return (
        <>
            <Header onNewUrl={handleNewUrl} />

            <Sheet
                sx={{
                    height: 'calc(100vh - 64px)',
                    overflowY: 'auto',
                    padding: '1rem'
                }}
            >
                <URLTable
                    urls={urls}
                    onDelete={handleDelete}
                    onToggleVisibility={handleToggleVisibility}
                    onLoadMore={loadData}
                    hasMore={!!lastEvaluatedKey}
                />
            </Sheet>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={notificationMsg.msg !== ''}
                onClose={() => setNotificationMsg({msg: '', color: 'neutral'})}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                color={notificationMsg.color as any}
                autoHideDuration={5000}
            >
                {notificationMsg.msg}
            </Snackbar>

        </>
    )
}

export default App
