import React from 'react'
import { Sheet, Table, Dropdown, MenuButton, MenuItem, Menu, IconButton, Link } from '@mui/joy'
import MoreVert from '@mui/icons-material/MoreVert'
import { ShortUrl } from '../Hooks/useURL'
import useAuthentication from '../Hooks/useAuthentication'

export interface ActionProps {
    url: ShortUrl
    onDelete: (slug: string) => void
    onToggleVisibility: (slug: string, newState: ShortUrl['visible']) => void
}

const Actions = (props: ActionProps) => {
    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
            >
                <MoreVert />
            </MenuButton>
            <Menu>
                <MenuItem
                    onClick={() => props.onToggleVisibility(props.url.slug, props.url.visible === '0' ? '1' : '0')}
                >
                    Toggle Visibility
                </MenuItem>
                <MenuItem onClick={() => props.onDelete(props.url.slug)}>Delete</MenuItem>
            </Menu>
        </Dropdown>
    )
}

export interface URLTableProps {
    urls: ShortUrl[]
    onLoadMore: () => void
    hasMore: boolean
    onDelete: (slug: string) => void
    onToggleVisibility: (slug: string, newState: ShortUrl['visible']) => void
}

const URLTable = (props: URLTableProps) => {
    const [lastElementObserver, setLastElementObserver] = React.useState<IntersectionObserver | null>(null)

    const { isAuthenticated } = useAuthentication()

    // if there is a change to the loaded items,
    // add an intersection observer to thr last item.
    React.useEffect(() => {
        // add intersection observer to last element
        if (lastElementObserver) {
            lastElementObserver.disconnect()
        }

        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    if (props.hasMore) {
                        props.onLoadMore()
                    }
                }
            }
        )
        const lastItem = document.querySelector('tbody#scroll-container > *:last-of-type')

        if (lastItem) {
            observer.observe(lastItem)
            setLastElementObserver(observer)
        }
    }, [props.urls])

    return (
        <Sheet>
            <Table>
                <thead>
                    <tr>
                        <th style={{width: '50%'}}>Target</th>
                        <th style={{width: '30px'}}>Slug</th>
                        <th style={{width: '30px'}}>Date Created</th>
                        {isAuthenticated() && <th style={{width: '30px'}}>Private</th>}
                        {isAuthenticated() && <th style={{width: '30px'}}>Actions</th>}
                    </tr>
                </thead>
                <tbody id='scroll-container'>
                    {props.urls.map(url => (
                        <tr key={url.slug}>
                            <td><Link href={url.target} target="_blank">{url.target}</Link></td>
                            <td><Link href={'./'+url.slug} target="_blank">{url.slug}</Link></td>
                            <td>{new Date(parseInt(url.dateCreated)).toLocaleDateString()}</td>

                            {isAuthenticated() &&
                                <td>{url.visible === '0' ? 'Yes' : 'No'}</td>
                            }

                            {isAuthenticated() &&
                                <td>
                                    <Actions
                                        url={url}
                                        onDelete={props.onDelete}
                                        onToggleVisibility={props.onToggleVisibility}
                                    />

                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

export default URLTable
