import useAuthentication from './useAuthentication'

export interface ShortUrl {
    slug: string
    target: string
    dateCreated: string
    visible: '1'|'0'
    description: string
}

export interface PageOptions {
    from?: {
        visible: string
        dateCreated: string
    },
    limit?: number
}

export interface DynamoCollectionResponse<T, K> {
    $metadata: {
        httpStatusCode: number,
        requestId: string,
        extendedRequestId: string|undefined,
        cfId: string|undefined,
        attempts: number,
        totalRetryDelay: number,
    }
    Items: Array<T>
    Count: number
    ScannedCount: number
    LastEvaluatedKey?: K
}

const useURL = () => {
    const auth = useAuthentication()

    return {
        getURL: async (slug: string): Promise<ShortUrl> => {
            const url = new URL(`./api/url/${slug}`, window.location.origin)
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return (await response.json())
        },

        getPublicURLCollection: async (options: PageOptions): Promise<DynamoCollectionResponse<ShortUrl, PageOptions['from']>> => {
            const url = new URL('./api/url/public', window.location.origin)
            url.searchParams.set('limit', options.limit?.toString() || '10')
            if (options.from) {
                url.searchParams.set('from', JSON.stringify(options.from))
            }
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return (await response.json())
        },

        getPrivateURLCollection: async (options: PageOptions): Promise<DynamoCollectionResponse<ShortUrl, PageOptions['from']>> => {
            const url = new URL('./api/url/private', window.location.origin)
            url.searchParams.set('limit', options.limit?.toString() || '10')
            if (options.from) {
                url.searchParams.set('from', JSON.stringify(options.from))
            }
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': auth.getToken() || ''
                }
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return (await response.json())
        },

        addURL: async (opts: Omit<ShortUrl, 'dateCreated'>): Promise<ShortUrl> => {
            const url = new URL('./api/url', window.location.origin)
            const resp = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': auth.getToken() || ''
                },
                body: JSON.stringify(opts)
            })

            if (resp.status !== 200) {
                throw new Error(`Failed to add URL: ${await resp.text()}`)
            }

            return (await resp.json())
        },

        setVisibility: async (slug: string, visibility: '1'|'0'): Promise<'1'|'0'> => {
            const url = new URL(`./api/url/set-visibility/${slug}/${visibility}`, window.location.origin)
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': auth.getToken() || ''
                }
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return (await response.text() as '1'|'0')
        },

        deleteURL: async (slug: string): Promise<void> => {
            const url = new URL(`./api/url/${slug}`, window.location.origin)
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': auth.getToken() || ''
                }
            })
            if (!response.ok) {
                throw new Error(response.statusText)
            }
        },
    }
}

export default useURL
