import {
    AuthFlowType,
    CognitoIdentityProvider,
    CognitoIdentityProviderClient,
    InitiateAuthCommand
} from '@aws-sdk/client-cognito-identity-provider'
import { jwtDecode } from 'jwt-decode'
import { useLocalStorage } from '@rehooks/local-storage'

const region = process.env.AWS_REGION
const userPoolClientId = process.env.USER_POOL_CLIENT_ID


const requestAuthentication = async (username: string, password: string): Promise<string> => {
    const client = new CognitoIdentityProviderClient({
        region
    })

    const command = new InitiateAuthCommand({
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
        ClientId: userPoolClientId,
    })

    const data = await client.send(command)
    const token = data.AuthenticationResult?.AccessToken
    if (!token) {
        throw new Error('No token found')
    }
    return token
}


const revokeAuthentication = async (token?: string) => {
    if (!token) return

    const client = new CognitoIdentityProvider({
        region,
    })

    return client.globalSignOut({AccessToken: token})
}


const isTokenExpired = (token: string|null) => {
    if (!token) return true
    const { exp } = jwtDecode(token)
    if (!exp) return true

    const isTokenExpired = Date.now() >= exp * 1000
    return isTokenExpired
}

const SESSION_AUTH_TOKEN = 'session_auth_token'

const useAuthentication = () => {

    const [token, setToken, deleteToken] = useLocalStorage(SESSION_AUTH_TOKEN)

    return {
        isAuthenticated(): boolean {
            return !isTokenExpired(token)
        },
        getToken(): string|undefined {
            return token || undefined
        },
        async login(username: string, password: string): Promise<string> {
            const jwt = await requestAuthentication(username, password)
            setToken(jwt)
            return jwt
        },
        async logout(): Promise<void> {
            await revokeAuthentication(token || undefined)
                .catch(console.error)
                .finally(() => {
                    deleteToken()
                })
        },
    }
}

export default useAuthentication
