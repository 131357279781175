import React from 'react'
import Modal from '@mui/joy/Modal'
import Button from '@mui/joy/Button'
import Input from '@mui/joy/Input'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'
import useAuthentication from '../Hooks/useAuthentication'

export interface LoginModalProps {
    open: boolean
    onClose: () => void
}

const LoginModal = (props: LoginModalProps) => {
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const auth = useAuthentication()

    const handleLogin = async () => {
        setLoading(true)
        try {
            await auth.login(username, password)
            props.onClose()
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Sheet
                sx={{
                    width: '300px',
                    padding: '20px',
                    // place in center of screen
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Stack>
                    <Input
                        type="text"
                        placeholder="Username"
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                    <Button
                        onClick={handleLogin}
                        loading={loading}
                    >Login</Button>
                </Stack>
            </Sheet>
        </Modal>
    )
}

export default LoginModal
