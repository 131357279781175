import React from 'react'
import { Button, Stack } from '@mui/joy'
import LoginModal from './LoginModal'
import useAuthentication from '../Hooks/useAuthentication'
import NewURLModal from './NewURLModal'
import { ShortUrl } from '../Hooks/useURL'

export interface HeaderProps {
    onNewUrl: (url: ShortUrl) => void
}


const Header = (props: HeaderProps) => {
    const { isAuthenticated, logout } = useAuthentication()
    const [loginModalOpen, setLoginModalOpen] = React.useState(false)
    const [newURLModalOpen, setNewURLModalOpen] = React.useState(false)

    const handleLoginClick = () => {
        if (isAuthenticated()) {
            logout()
        } else {
            setLoginModalOpen(true)
        }
    }

    return (
        <>
            <header>
                <Stack
                    direction="row"
                    // justifyContent="space-between"
                    padding="10px"
                    alignItems="center"
                    gap="10px"
                >
                    <Button onClick={handleLoginClick}>
                        {isAuthenticated() ? 'Logout' : 'Login'}
                    </Button>

                    {isAuthenticated() && <Button onClick={() => setNewURLModalOpen(true)}>Add URL</Button>}
                </Stack>
            </header>

            <LoginModal
                open={loginModalOpen}
                onClose={() => setLoginModalOpen(false)}
            />

            <NewURLModal
                open={newURLModalOpen}
                onClose={() => setNewURLModalOpen(false)}
                onSuccess={(url) => props?.onNewUrl?.(url)}
            />
        </>
    )
}

export default Header
