import React from 'react'
import Modal from '@mui/joy/Modal'
import Button from '@mui/joy/Button'
import Input from '@mui/joy/Input'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import FormHelperText from '@mui/joy/FormHelperText'
import { isUri } from 'valid-url'
import { sha256 } from 'js-sha256'
import useURL, { ShortUrl } from '../Hooks/useURL'
import Textarea from '@mui/joy/Textarea'

// validate target URL
const validateTarget = (target: string, onError: (error: string) => void) => {
    if (!isUri(target)) {
        onError('Invalid URL')
        return false
    }
    onError('')
    return true
}

// validate slug
const validateSlug = (slug: string, onError: (error: string) => void) => {
    if (!slug) {
        onError('Slug is required')
        return false
    }

    if (slug.length < 3) {
        onError('Slug must be at least 3 characters')
        return false
    }

    if (!isUri(`https://example.com/${slug}`)) {
        onError('Invalid slug')
        return false
    }

    onError('')
    return true
}

export interface NewURLModalProps {
    open: boolean
    onClose: () => void
    onSuccess?: (url: ShortUrl) => void
    onError?: (error: string) => void
}

const NewURLModal = (props: NewURLModalProps) => {
    const { addURL } = useURL()
    const [description, setDescription] = React.useState('')
    const [target, setTarget] = React.useState('')
    const [slug, setSlug] = React.useState('')
    const [targetError, setTargetError] = React.useState('')
    const [slugError, setSlugError] = React.useState('')
    const [autoSlug, setAutoSlug] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')

    React.useEffect(() => {
        if (target) {
            const isValid = validateTarget(target, setTargetError)
            if (isValid && autoSlug) {
                const hash = sha256.create()
                hash.update(target)
                setSlug(hash.hex().slice(0, 6))
            }
        }
    }, [target])

    React.useEffect(() => {
        if (slug) {
            validateSlug(slug, setSlugError)
        }
    }, [slug])

    const handleSubmit = async () => {
        if (validateTarget(target, setTargetError) && validateSlug(slug, setSlugError)) {
            setLoading(true)
            try {
                const resp = await addURL({ description, target, slug, visible: '0' })
                props?.onSuccess?.(resp)
                props.onClose()
            } catch (error) {
                const { message } = error as Error
                setError(message)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Sheet
                sx={{
                    width: '300px',
                    padding: '20px',
                    // place in center of screen
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Stack
                    spacing={2}
                >
                    <FormControl error={!!error}>

                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                minRows={3}
                                maxRows={3}
                                placeholder="Description"
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                            />
                        </FormControl>

                        <FormControl error={!!targetError}>
                            <FormLabel>Target URL</FormLabel>
                            <Input
                                type='text'
                                placeholder="Target URL"
                                onChange={e => setTarget(e.target.value)}
                                value={target}
                            />
                            <FormHelperText>
                                {targetError}
                            </FormHelperText>
                        </FormControl>

                        <FormControl error={!!slugError}>
                            <FormLabel>Slug</FormLabel>
                            <Input
                                type="text"
                                placeholder="Slug"
                                onChange={e => setSlug(e.target.value)}
                                value={slug}
                                // set padlock right decorator
                                endDecorator={
                                    <Button
                                        onClick={() => setAutoSlug(!autoSlug)}
                                    >
                                        {autoSlug ? '🔒' : '🔓'}
                                    </Button>
                                }
                            />
                            <FormHelperText>
                                {slugError}
                            </FormHelperText>
                        </FormControl>

                        <FormHelperText>
                            {error}
                        </FormHelperText>
                    </FormControl>


                    <Button
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Add URL
                    </Button>
                </Stack>
            </Sheet>
        </Modal>
    )
}

export default NewURLModal
